@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@font-face {
  font-family: MA;
  src: url(./assets/fonts/MontserratAlternates-SemiBold.ttf);
  font-display: swap; /* This ensures text is displayed using a system font until the web font is available. */
}

@media (prefers-color-scheme: light) {
  ::backdrop {
    background-color: #fff;
  }
}

body {
  background-color: var(--background);
}

/* THemes */

.Dark {
  --background: #062527;
  --wrappers: #363c47;
  --outcolor: #000000;
  --barcolors: #8590a6;
  --intext: #b6b1b1;
  --headers: #ffffff;
  --elmcolors: #ffffff;
  --ptext: #ffffff;
  --btext: #f4f4ff;
}
.Gotham {
  --background: #000000;
  --image: url(../src/assets/wallpapersden.com_batman-4k-gotham-city-digital_3840x2400.webp);
  --wrappers: #3a3939;
  --outcolor: #000000;
  --barcolors: #9d9898;
  --intext: #ffffff;
  --headers: #ffffff;
  --elmcolors: #ffffff;
  --ptext: #ffffff;
  --btext: #f4f4f4;
}

.Fantasy {
  --background: #421150;
  --wrappers: #896784;
  --elmcolors: #fffbd5e8;
  --outcolor: #a3234e;
  --barcolors: #b28fad;
  --intext: #d8b8b8;
  --headers: #ffffff;
  --ptext: #ffb5b5;
  --btext: #eee7ff;
}
.Obsidian {
  --background: #2e293a;
  --wrappers: #4b2e5d;
  --outcolor: #5b4965;
  --barcolors: #b6b1b1;
  --intext: #b39ac2;
  --headers: #ffffff;
  --elmcolors: #9f6fba;
  --ptext: #ffffff;
  --btext: #decfff;
}
.Magenta {
  --background: #480e52;
  --wrappers: #733b6b;
  --outcolor: #620b56;
  --barcolors: #e6c4e3;
  --intext: #b6b1b1;
  --headers: #ffffff;
  --elmcolors: #e58abe;
  --ptext: #ffffff;
  --btext: #ffc3f8;
}
.Lavender {
  --background: #450b75;
  --wrappers: #8246cc9e;
  --elmcolors: #dac9ff;
  --outcolor: #804bab;
  --barcolors: #dfd2ea;
  --intext: #b6b1b1;
  --headers: #ffffff;
  --ptext: #ffffff;
  --btext: #f5e9ffe8;
}
.Carbon {
  --background: #0b245d;
  --wrappers: #133279;
  --elmcolors: #728dee;
  --outcolor: #0052f8;
  --barcolors: #c2cfe7cf;
  --intext: #b6b1b1;
  --headers: #ffffff;
  --ptext: #ffffff;
  --btext: #fafafa;
}
.Pacific {
  --background: #082551;
  --wrappers: #021b51d5;
  --outcolor: #3555869d;
  --barcolors: #9aaac3;
  --intext: #b6b1b1;
  --image: url(../src/assets/Untitled\ design.webp);
  --headers: #ffffff;
  --elmcolors: #abacfcbd;
  --ptext: #ffffff;
  --btext: #ccd4e1;
  background-size: cover;
}
.Coffee {
  --background: #38220f;
  --wrappers: #967259;
  --outcolor: #634832;
  --barcolors: #dbc1ac;
  --intext: #a7a5a5;
  --headers: #ffffff;
  --elmcolors: #ffffff;
  --ptext: #dbc1ac;
  --btext: #dbc1ac;
}
.Strawberry {
  --background: #e9bfcc;
  --image: url(../src/assets/strawberry.webp);
  --wrappers: #d16a89;
  --outcolor: #ae0c56;
  --barcolors: #ff96b5;
  --intext: #d0bbbb;
  --elmcolors: #9e0146;
  --headers: #ffffff;
  --ptext: #ffffff;
  --btext: #f2dce4;
  box-shadow: 0rem 2rem 4rem 1rem var(--barcolors) inset;
}
.Bush {
  --background: #443508;
  --wrappers: #026625e8;
  --elmcolors: #d0e7d2;
  --outcolor: #4b8c00;
  --barcolors: #a4ddc3eb;
  --intext: #b6b1b1;
  --image: url(../src/assets/wallpaperflare.com_wallpaper.webp);
  --headers: #ffffff;
  --ptext: #ffffff;
  --btext: #c3ebc4;
}
.Cream {
  --background: #6d4949;
  --wrappers: #c17e7e;
  --outcolor: #f18282;
  --barcolors: #f6c1c1;
  --intext: #b6b1b1;
  --headers: #ffffff;
  --elmcolors: #f6c1c1;
  --ptext: #6b4444;
  --btext: #f4f4ff;
}
.Dark,
.Obsidian,
.Sunset,
.Lavender,
.Magenta,
.Coffee,
.Pacific,
.Carbon,
.Cream,
.Fantasy,
.Gotham,
.Bush,
.Strawberry {
  padding: 4% 4%;
  background: var(--background);
}
.Gotham,
.Pacific,
.Bush,
.Strawberry {
  background: var(--image);
  background-size: cover;
  background-position-x: 50%;
}
.Strawberry {
  background-repeat: repeat;
  background-size: 110%;
}
.Pacific,
.Strawberry {
  background-position-x: 60%;
}
/* Themes above */
::-webkit-scrollbar {
  width: 0;
}
.App {
  text-align: center;
  background-color: var(--background);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: none;
}
.todolist {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow-y: scroll;
  scroll-behavior: smooth;
  flex-direction: column;
  height: 15rem;
}
.todolist::-webkit-scrollbar {
  width: 3px;
  border-radius: 3px;
  background-color: var(--wrappers);
  color: var(--outcolor);
}
.layo p {
  text-align: initial;
  line-height: 1.5rem;
  font-size: 85%;
  font-weight: 500;
  font-family: "MA";
}
.Todowrapper {
  border-radius: 23px;
  padding: 1rem 1rem;
  align-items: center;
  background: var(--wrappers);
  max-height: 23.8rem;
  display: flex;
  min-width: 39%;
  max-width: 40%;
  flex-direction: column;
}
.Todowrapper h1 {
  margin: 0rem 0 1rem;
  font-size: 2rem;
  color: var(--btext);
}
.trash {
  margin-left: 2rem;
}
input.input-todo {
  font-size: 18.5px;
  width: 75%;
  border: 2px solid var(--outcolor);
  background: var(--barcolors);
  font-weight: 600;
  padding: 2% 3%;
  color: var(--outcolor);
  border-radius: 11px 0 0 11px;
  font-family: "MA";
}

form.Todoform {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-bottom: 1rem;
  flex-direction: row;
  width: 90%;
}
body {
  height: 100vh;
}
.scrollbar-track {
  width: 0;
  display: none;
  opacity: 0;
  width: null;
}
.scrollbar-track-y {
  width: null;
}
.scrollbar-track.show,
.scrollbar-track:hover {
  display: none;
  opacity: 0;
  width: null;
}
.scrollbar-thumb {
  opacity: 0;
  display: none;
  width: null;
}
input.input-todo:focus {
  padding: 2% 3%;
  font-weight: 600;
  outline: none;
  stroke: none;
}
.kid1 {
  background: var(--wrappers);
}
.kid2 {
  background: var(--barcolors);
}
button.input-todo-button {
  background: var(--outcolor);
  color: var(--ptext);
  padding: 1% 2%;
  font-size: 17px;
  letter-spacing: 0.05em;
  font-family: "MA";
  border: 2px solid var(--outcolor);
  border-radius: 0 11px 11px 0;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
}
button.input-todo-button:hover {
  background: var(--btext);
  color: var(--outcolor);
  border-left: 2px solid var(--btext);
}
.input-todo-button:focus {
  outline: none;
  stroke: none;
  cursor: pointer;
}
.completed {
  color: var(--intext);
  text-decoration: line-through;
}
.Todo {
  display: flex;
  color: var(--ptext);
  background: var(--outcolor);
  width: 80%;
  margin: 1%;
  border-radius: 20px;
  padding: 2% 3%;
  align-self: center;
  font-size: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.layo {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.icons {
  cursor: pointer;
  font-size: 18px;
}

.nav-links li {
  text-decoration: none;
  display: flex;
  color: var(--elmcolors);
  cursor: pointer;
  transition: all 0.4s ease;
  flex-direction: column;
  align-items: center;
}
.web {
  transition: all 0.4s ease;
  color: var(--elmcolors);
}
.web:hover {
  color: var(--wrappers);
}
.lis::after {
  opacity: 0;
}
.nav-links li::after {
  content: " ";
  width: 40%;
  height: 4px;
  background-color: var(--btext);
  border-radius: 2px;
  text-align: center;
  display: block;
  align-self: center;
  transition: all 0.4s ease;
}
.nav-links li:hover::after {
  width: 30%;
}
.nav-links li:hover {
  color: var(--wrappers);
}
.headquote {
  color: var(--elmcolors);
}
.name {
  color: var(--elmcolors);
}
.icons {
  padding: 4px;
  border-radius: 4px;
  transition: all ease 0.4s;
}
.icons:hover {
  background: var(--elmcolors);
  padding: 4px;
  border-radius: 4px;
}
.todolist::-webkit-scrollbar {
  width: 12px;
  cursor: pointer;
}
.todolist::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--barcolors);
  cursor: pointer;
}

#tsparticles {
  z-index: 0;
  position: relative;
}
#task {
  z-index: 1;
  position: relative;
}
.nav {
  position: relative;
  z-index: 1;
}
.nhi,
.looks {
  display: none;
}

.footer {
  position: relative;
  z-index: 5;
  background-color: #000000;
}
.logo {
  color: #fff;
}
.footwrap {
  padding: 2rem 6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.leftcul {
  display: flex;
  flex-direction: column;
  text-align: initial;
  align-items: flex-start;
}

.leftcul p {
  margin: 1% 9%;
  font-size: 107%;
  width: 45%;
  color: #fff;
  font-family: Poppins;
}

ul.foot-links {
  text-align: end;
  list-style: none;
  min-width: 14rem;
  position: relative;
  display: flex;
  font-weight: bold;
  font-size: 150%;
  row-gap: 0.5rem;
  margin-right: 5%;
  flex-direction: column;
  letter-spacing: 0.05em;
  align-items: flex-end;
}

.foot-links a {
  color: white;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  right: 5rem;
}

.embed {
  width: 21px;
  border-radius: 49px;
  background: #dcaeffd1;
}

.downcul {
  font-size: 125%;
  margin: 1em;
  font-family: "MA";
  color: #ffffffa3;
}

.topbutton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  color: black;
  text-align: center;
  padding: 0;
  position: relative;
  left: 90%;
  bottom: 4rem;
  cursor: pointer;
  z-index: 7;
}
.topbutton a img {
  width: 100%;
}

.topbutton a {
  display: contents;
}
.todobutton:hover {
  background-color: #000000;
}
.todobutton a img:hover {
  filter: invert(1);
}
.links ul li a {
  cursor: pointer;
  color: #ffc9ee;
  transition: all ease-in-out 0.3s;
}
.links ul li a:hover {
  color: #a277ff;
}
.tope {
  width: 100%;
  padding: 9px;
  border-radius: 50%;
  scale: 1;
  rotate: 0deg;
  transition: all ease-in-out 0.3s;
}
.tope:hover {
  filter: invert(1);
  rotate: 360deg;
  background-color: #7eb87e;
}
.switch {
  text-decoration: none;
  color: var(--outcolor);
  font-family: "MA";
  transition: all 0.4s ease;
  background: var(--btext);
  border: 3px solid var(--btext);
  padding: 8px 17px;
  border-radius: 32px;
}

.switch:hover {
  background: transparent;
  color: var(--btext);
  border: 3px solid var(--elmcolors);
}

.slider {
  border: 2.7px solid var(--barcolors);
  height: 25px;
  padding: 0rem;
  border-radius: 11px;
}

.thumb {
  background-color: var(--barcolors);
  height: 100%;
  border-radius: 5px;
  width: 1.6rem;
}
.thumb:focus-visible {
  border: none;
  outline: none;
  stroke: none;
}
.Pomo {
  background: var(--wrappers);
  min-width: 39%;
  border-radius: 11px;
  padding: 2rem;
  max-width: 40%;
}
.pomo img {
  width: fit-content;
}
#time {
  position: relative;
  padding: 4rem 0 2rem 0;
  z-index: 3;
}
#time h1 {
  margin-bottom: 4%;
}
.pomo {
  background: linear-gradient(149deg, var(--wrappers), transparent);
  border-radius: 1rem 10rem 1rem 10rem;
  padding: 1rem 2px;
  width: 28rem;
}
.overlayys {
  display: flex;
  align-items: center;
  margin: 5rem 0 4rem 0;
  column-gap: 10%;
  justify-content: center;
  flex-direction: row;
}
.overllayz {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Timer {
  align-self: center;
  width: 10rem;
}
.Pomo h1 {
  font-size: 2rem;
  color: var(--btext);
  margin: 0 0 1rem;
}
text.CircularProgressbar-text {
  font-weight: bold;
  font-family: "Poppins";
  letter-spacing: 0.038em;
}
.buttons button {
  padding: 0.25rem 0.75rem;
  font-size: 1.2rem;
  border-radius: 50%;
  margin: 1rem 0;
  cursor: pointer;
  outline: none;
  stroke: none;
  background: var(--barcolors);
  border: 1px solid var(--intext);
  color: var(--outcolor);
  transition: all 0.3s;
  rotate: 0deg;
}
.buttons button:hover {
  border: 1px solid var(--outcolor);
  rotate: 360deg;
  background: var(--outcolor);
  color: var(--barcolors);
}
.settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.setter {
  width: 85%;
  row-gap: 5px;
  display: flex;
  flex-direction: column;
}
.Blogs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
  position: relative;
  z-index: 6;
}

.wrappers {
  width: 65%;
  display: flex;
  border-radius: 11px;
  flex-direction: column;
  align-items: center;
}
.desc {
  color: var(--btext);
  margin-left: 2%;
  font-size: 101%;
  font-weight: 400;
  margin-top: 0.4%;
}
.content img {
  border-radius: 11px;
}
.Flexbox {
  display: flex;
  margin: 1rem;
  flex-direction: column;
  align-items: center;
}

.Topics {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 11px;
  row-gap: 1rem;
  margin-top: 2rem;
  padding: 2rem;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  text-align: initial;
  background: var(--wrappers);
  border-radius: 15px;
  padding: 1%;
  column-gap: 18%;
}

.Flexbox h1 {
  color: var(--btext);
  font-size: 250%;
  letter-spacing: 0.03em;
}

.title {
  color: var(--btext);
  font-size: 110%;
  font-weight: 600;
  letter-spacing: 0.03em;
}
.a{
  color: var(--btext);
  font-weight:600;
  font-family: MA;
  font-size: 140%;
  transition: all ease 0.3s;
}
.a:hover{
  color: var(--barcolors);
}
.detail {
  color: var(--barcolors);
  font-size: 95%;
  margin-left: 1%;
  margin-top: 1%;
}
.detail a {
  color: var(--elmcolors);
  transition: 0.3s;
}
.detail a:hover {
  color: var(--btext);
}
.left {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2%;
}
.setter label {
  font-family: "Poppins";
  align-self: flex-start;
  margin: 3px;
  color: var(--btext);
  letter-spacing: 0.04em;
  font-weight: 800;
}
/* Mobiles */
@media (max-width: 1024px) and (min-width: 120px) {
  .nhi {
    display: contents;
    font-size: 180%;
    font-family: sans-serif;
    position: relative;
    font-weight: 800;
  }
  .footer p {
    display: contents;
    color: #000000;
    font-family: "MA";
  }
  .nav {
    display: none;
  }
  .task {
    display: none;
  }
  .App section {
    display: none;
  }
  .App {
    width: 100%;
    text-align: center;
    display: flex;
    height: 100vh;
    margin-top: 1rem;
    align-items: center;
    padding: 2rem;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .footera {
    display: none;
  }
}
