.nav {
  width: 100%;
}
a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.overlay {
  width: 100%;
}

.name {
  font-size: 2rem;
  font-weight: 900;
  font-family: "MA";
}
.nav {
  z-index: 4;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circles {
  display: inline-flex;
  justify-content: center;
  rotate: 0deg;
  transition: 0.5s;
}
.kid1 {
  position: relative;
}

.kid1,
.kid2 {
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  animation: coaxial;
  position: relative;
}

.kid1 {
  bottom: 7px;
  left: 25%;
}

.kid2 {
  top: 25%;
  filter: drop-shadow(0px 0px 2rem Majenda);
  right: 7px;
}

ul.nav-links {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  list-style: none;
  font-size: 120%;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}
.bar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  column-gap: 30%;
}
.image {
  max-width: 26.75rem;
  width: 100%;
  border-radius: 50%;
}
.image img {
  width: 100%;
  height: auto;
  border-radius: 50rem;
  bottom: 1rem;
  position: relative;
  filter: saturate(0.7) blur(0px);
  left: 1rem;
}
.task {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2.76rem 1rem;
}
.tasklay {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  margin: 1.4rem 0;
  column-gap: 15%;
  justify-content: center;
}
.headquote {
  font-family: "MA";
  font-size: 300%;
  margin: 0rem 0 4rem;
  letter-spacing: 0.04em;
}
.notification {
  position: absolute;
  background-color: var(--wrappers);
  color: var(--btext);
  padding: 8px 18px;
  border-radius: 40px;
  transition: all 0.5s ease-in-out;
  top: 10.5rem;
  font-family: "MA";
  z-index: 1;
}
.notification.active {
  filter: opacity(1);
}
.nav p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.details a {
  color: #d994de;
  font-weight: 600;
  transition: ease-in-out 0.3s;
  text-decoration: none;
}
.details a:hover {
  color: #de94a2;
}
.image,.pomo {
  padding: 25px;
  border-radius: 50%;
  background: linear-gradient(0deg, var(--wrappers), var(--outcolor));
}

.image img {
  top: 3px;
  position: relative;
}
